import IkBrandLogoYellow from "@/assets/IkBrandLogo-yello"
import SaveState from "@/components/SaveState"
import { builderWebconfigStateAtom, webstorePolicyUIStateAtom } from "@/state"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import { useAtom } from "jotai"
import React from "react"
import NudgeCompleteReviewPopup from "./ActionPanel/Settings/StorePolicy/Legal/NudgeCompleteReviewPopup"

export const NavbarBack = ({ showSaveState }: { showSaveState?: boolean }) => {
	const [builderWebconfig] = useAtom(builderWebconfigStateAtom)
	const [isPolicyReviewAccept] = useAtom(webstorePolicyUIStateAtom)
	const [openNudgeDialog, setOpenNudgeDialog] = React.useState(false)

	const tncsNotAccepted = (!!builderWebconfig?.legalWebstorePolicyTnCs?.acceptedAt === false && isPolicyReviewAccept)
	
	const handleClick = () => {
		if (tncsNotAccepted) {
			setOpenNudgeDialog(true)
		} 	
	}

	return (
		<>
			<Stack
				direction={"row"}
				alignItems={"center"}
				justifyContent={"flex-start"}
				gap={"1rem"}
				sx={{
					color: "#000",
				}}
			>
				<Tooltip title="Back to dashboard" placement="right-start" arrow>
					<Link underline="none" href={tncsNotAccepted ? "#" : process.env.NEXT_PUBLIC_MERCHANT_DASHBOARD_REDIRECT} >
						<IconButton onClick={() => handleClick()}>
							<IkBrandLogoYellow width={32} height={32} />
						</IconButton>
					</Link>
				</Tooltip>
				{showSaveState && <SaveState />}
			</Stack>
			<NudgeCompleteReviewPopup 
				open={openNudgeDialog} 
				redirectTo={process.env.NEXT_PUBLIC_MERCHANT_DASHBOARD_REDIRECT}
				onClose={() => setOpenNudgeDialog(false)} 
			/>			
		</>
	)
}
