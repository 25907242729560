import { SideMenuItem } from "@/enums"
import { useFinishComponentDraftEdit } from "@/hooks"
import {
	ActionType,
	builderMenuReducerAtom,
	builderWebconfigStateAtom,
	completeStorePolicyPopupNudge,
	webstorePolicyUIStateAtom,
} from "@/state"
import Icon from "@mui/material/Icon"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { Gear, ListBullets } from "@phosphor-icons/react"
import { useAtom, useSetAtom } from "jotai"
import React from "react"
import NudgeCompleteReviewPopup from "./ActionPanel/Settings/StorePolicy/Legal/NudgeCompleteReviewPopup"

type MenuItem = {
	text: string
	iconNode: React.ReactNode
	activeNode: React.ReactNode
	sideMenuItem: SideMenuItem
}

const menuItems: MenuItem[] = [
	{
		text: "Guide",
		iconNode: <ListBullets />,
		activeNode: <ListBullets />,
		sideMenuItem: SideMenuItem.Setup_Guide,
	},
	{ text: "Settings", iconNode: <Gear />, activeNode: <Gear />, sideMenuItem: SideMenuItem.Settings },
]

const MenuItem = (props: MenuItem) => {
	const [menuState, dispatch] = useAtom(builderMenuReducerAtom)
	const [builderWebconfig] = useAtom(builderWebconfigStateAtom)
	const [isPolicyReviewAccept] = useAtom(webstorePolicyUIStateAtom)
	const setOpenNudgeDialog = useSetAtom(completeStorePolicyPopupNudge)
	const { finishDraftEdit } = useFinishComponentDraftEdit()

	const handleClick = () => {
		if (!!builderWebconfig?.legalWebstorePolicyTnCs?.acceptedAt === false && isPolicyReviewAccept) {
			setOpenNudgeDialog(true)

			return
		}

		dispatch({ type: ActionType.SideMenuItemSelected, payload: props.sideMenuItem })
		finishDraftEdit()
	}

	const active = menuState.activeMenuItem === props.sideMenuItem

	return (
		<Stack
			sx={{ cursor: "pointer" }}
			direction={"column"}
			alignItems={"center"}
			onClick={handleClick}
			color={active ? "secondary.main" : "inherit"}
			borderBottom={active ? "2px solid" : "2px solid transparent"}
			width={"4.2rem"}
			boxSizing={"border-box"}
			height={"3.625rem"}
		>
			<Icon>{active ? props.activeNode : props.iconNode}</Icon>
			<Typography variant={active ? "body1" : "body2"} color={active ? "secondary.main" : "inherit"}>
				{props.text}
			</Typography>
		</Stack>
	)
}

export const SideMenu = () => {
	const [openNudgeDialog, setOpenNudgeDialog] = useAtom(completeStorePolicyPopupNudge)

	return (
		<>
			<Stack gap={"1rem"} height={"100%"}>
				{menuItems.map((item) => (
					<MenuItem key={item.text} {...item} />
				))}
			</Stack>
			<NudgeCompleteReviewPopup
				open={openNudgeDialog}
				sideMenuItem={SideMenuItem.Setup_Guide}
				onClose={() => setOpenNudgeDialog(false)}
			/>
		</>
	)
}
