import TextField, { getMaxCharacterMutationFn, trim } from "@/components/TextField"
import { builderValuesAtom } from "@/state"
import Box from "@mui/material/Box"
import { useAtomValue } from "jotai"
import React from "react"

const maxCharacterCount = 200
const blurMutationFns = [trim]
const changeMutationFns = [getMaxCharacterMutationFn(maxCharacterCount)]

const ofMaxCharactersText = (currentLength: number) => `Max characters ${currentLength}/${maxCharacterCount}`

export const About = () => {
	const { fieldErrors, handleOnChange, values } = useAtomValue(builderValuesAtom)

	const maxCharactersHelperText = ofMaxCharactersText((values.aboutUs as string)?.length ?? 0)

	return (
		<Box p="1rem">
			<TextField
				name="about"
				variant="outlined"
				color="secondary"
				label="Enter about us (optional)"
				multiline
				helperText={fieldErrors.aboutUs?.message ?? maxCharactersHelperText}
				onChange={(e) => handleOnChange("aboutUs")(e.target.value)}
				value={values.aboutUs}
				fullWidth
				error={fieldErrors.aboutUs?.message}
				size="medium"
				blurMutationFns={blurMutationFns}
				changeMutationFns={changeMutationFns}
				minRows={4}
			/>
		</Box>
	)
}
