import alert from "@/assets/Alert.svg"
import { SideMenuItem } from "@/enums"
import {
	ActionType,
	builderMenuReducerAtom,
	settingsUIAtom,
	SettingsUIState,
	webstorePolicyUIStateAtom
} from "@/state"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import { Button, Dialog, DialogContent, Divider, IconButton, Stack, Typography } from "@mui/material"
import { X } from "@phosphor-icons/react"
import { useSetAtom } from "jotai"

type PopupProps = {
	open: boolean
	onClose: () => void
	sideMenuItem?: SideMenuItem
	redirectTo?: string
}

const NudgeCompleteReviewPopup = (props: PopupProps) => {
	const { open, onClose, sideMenuItem, redirectTo } = props
	const setSettingsUI = useSetAtom(settingsUIAtom)
	const dispatch = useSetAtom(builderMenuReducerAtom)
	const setIsPolicyReviewAccept = useSetAtom(webstorePolicyUIStateAtom)

	const handleConfirmExit = () => {
		if (redirectTo) {
			window.location.href = redirectTo

			return
		}

		setIsPolicyReviewAccept(false)

		if (sideMenuItem) {
			onClose()
			dispatch({ type: ActionType.SideMenuItemSelected, payload: props.sideMenuItem })

			return	
		}

		setSettingsUI({ uiState: SettingsUIState.None, alert: "" })
	}

	return (
		<Dialog
			PaperProps={{
				sx: {
					borderRadius: ".625rem",
					width: "27.75rem",
					maxHeight: "26.5rem",
				},
			}}
			open={open}
		>
			<IconButton
				aria-label="close"
				onClick={handleConfirmExit}
				sx={{
					position: "absolute",
					right: ".75rem",
					top: ".75rem",
					color: palette.common.black,
				}}
			>
				<X />
			</IconButton>
			<DialogContent sx={{ padding: "0rem 1.5rem 1.25rem 1.5rem", marginTop: "4rem" }}>
				<Stack
					sx={{
						alignItems: "center",
					}}
				>
					<img style={{ width: "9.375rem" }} src={alert.src} alt="info" />

					<Divider sx={{ height: "1rem", backgroundColor: "transparent" }} />

					<Typography variant="body2" align="center" color={"#5F2120"}>
						In order to be able to publish your website, you will need to read through and accept all store policies.{" "}
					</Typography>
					<Divider sx={{ height: "1rem", backgroundColor: "transparent" }} />
					<Button
						sx={{ width: "24.75rem" }}
						color="primary"
						size="large"
						variant={"contained"}
						onClick={() => {
							onClose()
						}}
					>
						{"Back to store policies"}
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}

export default NudgeCompleteReviewPopup
