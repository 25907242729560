import { HeroContent } from "@/components/builder/ActionPanel/Guide/HeroContent"
import Settings from "@/components/builder/ActionPanel/Settings"
import { SideMenuItem } from "@/enums"
import { useFinishComponentDraftEdit } from "@/hooks"
import { ActionType, builderMenuReducerAtom, EditItem, settingsUIAtom, SettingsUIState } from "@/state"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { ArrowLeft, X } from "@phosphor-icons/react"
import { useAtomValue, useSetAtom } from "jotai"
import React from "react"
import { About, BusinessHours, SetupGuide, Socials, StoreDetails } from "./ActionPanel"

export const SideMenuActionPanel = () => {
	const menuState = useAtomValue(builderMenuReducerAtom)
	const setSettingsUI = useSetAtom(settingsUIAtom)

	let component = SetupGuide
	let title = "Setup Guide"

	if (menuState.activeMenuItem === SideMenuItem.Setup_Guide) {
		setSettingsUI({ uiState: SettingsUIState.None, alert: "" })

		switch (menuState.activeEditingItem) {
			case EditItem.HeroContent:
				component = HeroContent
				title = "Banner text"
				break
			case EditItem.AboutUs:
				component = About
				title = "About Us"
				break
			case EditItem.BusinessHours:
				component = BusinessHours
				title = "Business Hours"
				break
			case EditItem.StoreDetails:
				component = StoreDetails
				title = "Store Details"
				break
			case EditItem.Socials:
				component = Socials
				title = "Socials"
				break
		}
	}

	if (menuState.activeMenuItem === SideMenuItem.Settings) {
		component = Settings
		title = "Settings"
	}

	return (
		<Box
			sx={{
				height: "100%",
				overflowY: "auto",
				paddingTop: "1rem",
				width: "18.25rem",
				boxSizing: "border-box",
			}}
		>
			{menuState.activeMenuItem === SideMenuItem.Setup_Guide &&
				(menuState.activeEditingItem === EditItem.None ? (
					<BuilderMenuTitle title={title} />
				) : (
					<BuilderEditItemTitle title={title} />
				))}
			{React.createElement(component)}
		</Box>
	)
}

type BuilderTitleProps = {
	title: string
}

export function BuilderMenuTitle({ title }: Readonly<BuilderTitleProps>) {
	const dispatch = useSetAtom(builderMenuReducerAtom)

	const handleCloseActionPanel = () => {
		dispatch({ type: ActionType.Close })
	}

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" px="1rem" margin="1rem 0 1.5rem">
			<Typography sx={alert.title}>{title}</Typography>
			<IconButton onClick={handleCloseActionPanel}>
				<X />
			</IconButton>
		</Stack>
	)
}

function BuilderEditItemTitle({ title }: Readonly<BuilderTitleProps>) {
	const { finishDraftEdit } = useFinishComponentDraftEdit()

	const dispatch = useSetAtom(builderMenuReducerAtom)

	const handleCloseEditItemPanel = () => {
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.None } })
		finishDraftEdit()
	}

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" px="1rem" margin="1rem 0">
			<Button
				sx={{ justifyContent: "flex-start" }}
				color="inherit"
				variant="text"
				startIcon={<ArrowLeft size={18} />}
				onClick={handleCloseEditItemPanel}
			>
				<Typography sx={alert.title} textTransform="none">
					{title}
				</Typography>
			</Button>
		</Stack>
	)
}
